export const firmConstants = {
  GET_FRANCHISE_DETAILS_LOADING: "GET_FRANCHISE_DETAILS_LOADING",
  GET_FRANCHISE_DETAILS_SUCCESS: "GET_FRANCHISE_DETAILS_SUCCESS",
  GET_FRANCHISE_DETAILS_FAILURE: "GET_FRANCHISE_DETAILS_FAILURE",

  RESET_IMAGE_URL: "RESET_IMAGE_URL",

  GET_BROKRAGE_DETAILS_LOADING: "GET_BROKRAGE_DETAILS_LOADING",
  GET_BROKRAGE_DETAILS_SUCCESS: "GET_BROKRAGE_DETAILS_SUCCESS",
  GET_BROKRAGE_DETAILS_FAILURE: "GET_BROKRAGE_DETAILS_FAILURE",

  CREATE_FRANCHISE_DETAILS_LOADING: "CREATE_FRANCHISE_DETAILS_LOADING",
  CREATE_FRANCHISE_DETAILS_SUCCESS: "CREATE_FRANCHISE_DETAILS_SUCCESS",
  CREATE_FRANCHISE_DETAILS_FAILURE: "CREATE_FRANCHISE_DETAILS_FAILURE",

    POST_IMAGE_LOADING: "POST_IMAGE_LOADING",
    POST_IMAGE_FAILURE: "POST_IMAGE_FAILURE",
    POST_IMAGE_SUCCESS: "POST_IMAGE_SUCCESS",


    EDIT_FRANCHISE_DETAILS_LOADING: "EDIT_FRANCHISE_DETAILS_LOADING",
    EDIT_FRANCHISE_DETAILS_SUCCESS: "EDIT_FRANCHISE_DETAILS_SUCCESS",
    EDIT_FRANCHISE_DETAILS_FAILURE: "EDIT_FRANCHISE_DETAILS_FAILURE",

  SEARCH_ADMIN_LOADING: "SEARCH_ADMIN_LOADING",
  SEARCH_ADMIN_ERROR: "SEARCH_ADMIN_ERROR",
  SEARCH_ADMIN_DATA: "SEARCH_ADMIN_DATA",
  RESET_SEARCH_ADMIN_DATA: "RESET_SEARCH_ADMIN_DATA",

  SEND_MAIL_FOR_CLAUSES_LOADING: "SEND_MAIL_FOR_CLAUSES_LOADING",
  SEND_MAIL_FOR_CLAUSES_SUCCESS: "SEND_MAIL_FOR_CLAUSES_SUCCESS",
  SEND_MAIL_FOR_CLAUSES_FAILURE: "SEND_MAIL_FOR_CLAUSES_FAILURE",

  GET_ACCESS_TO_FJGG_DOCANDCLAUSES_LAODING : "GET_ACCESS_TO_FJGG_DOCANDCLAUSES_LAODING",
  GET_ACCESS_TO_FJGG_DOCANDCLAUSES_SUCCESS : "GET_ACCESS_TO_FJGG_DOCANDCLAUSES_SUCCESS",
  GET_ACCESS_TO_FJGG_DOCANDCLAUSES_ERROR : "GET_ACCESS_TO_FJGG_DOCANDCLAUSES_ERROR",


  GET_COMPANY_DETAILS_LOADING: "GET_COMPANY_DETAILS_LOADING",
  GET_COMPANY_DETAILS_SUCCESS: "GET_COMPANY_DETAILS_SUCCESS",
  GET_COMPANY_DETAILS_FAILURE: "GET_COMPANY_DETAILS_FAILURE",

  GET_OFFICE_DETAILS_LOADING: "GET_OFFICE_DETAILS_LOADING",
  GET_OFFICE_DETAILS_SUCCESS: "GET_OFFICE_DETAILS_SUCCESS",
  GET_OFFICE_DETAILS_FAILURE: "GET_OFFICE_DETAILS_FAILURE",

  GET_BROKERAGE_GROUP_LOADING: "GET_BROKERAGE_GROUP_LOADING",
  GET_BROKERAGE_GROUP_ERROR: "GET_BROKERAGE_GROUP_ERROR",
  GET_BROKERAGE_GROUP_SUCCESS: "GET_BROKERAGE_GROUP_SUCCESS",
}
