/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Card, Col, Input, List, Row } from "antd";
import React, { useEffect, useState } from "react";
import { EmptyData } from "../../Common/components/EmptyData";
import { useMediaQuery } from "react-responsive";
import { DeleteTwoTone,  UserOutlined } from "@ant-design/icons";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { generateInitialsFromObj } from "../../Common/utils/universalFuntions";
import { documentAction } from "../../DocumentSection/state/actions";
import styles from "../styles/CobuyerCosellerList.module.css";

export default function ManualAddSeller({ currentUrlId, contractAuthKey, source, clientType, currentlySelectedData, clientsArray, setClientsArray }) {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [selectedRows, setSelectesRows] = useState([]);
  const [addPersons, setAddPerson] = useState(false);
  const [clientName, setClientName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentlySelectedData?.isCorp) {
      setSelectesRows(currentlySelectedData.signers);
    } else {
      let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
      const clients = localData[clientType] || [];
      setSelectesRows(clients.filter((ele) => !ele.isCorp));
    }
  }, [currentlySelectedData, clientType]);

  const handleManualClientAdd = () => {
    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    const clients = localData[clientType] || [];
    let clientInfo = {
      fullName: clientName?.trim(),
      personId: null,
      isCorp: false,
      signers: [],
      key: uuidv4(),
    };
    if (currentlySelectedData?.isCorp) {
      let newDataIndex = clients.findIndex((el) => el.key === currentlySelectedData.key);
      if (newDataIndex !== -1) {
        localData[clientType] = [...clients.slice(0, newDataIndex), { ...clients[newDataIndex], signers: [...(clients[newDataIndex].signers || []), clientInfo] }, ...clients.slice(newDataIndex + 1)];
      }
    } else {
      localData[clientType] = [...clients, clientInfo];
    }

    setClientsArray(localData[clientType]);
    setSelectesRows([...selectedRows, clientInfo]);
    localStorage.setItem(currentUrlId, JSON.stringify(localData));
    if (contractAuthKey) {
      dispatch(contractStoreHandle.autoSaveContract({ currentUrlId, contractAuthKey, valueObj: { [clientType]: localData[clientType] } }));
    } else {
      dispatch(documentAction.setpdfImportState({ [clientType]: localData[clientType] }));
    }
    setClientName("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleManualClientAdd();
    }
  };

  const handleDeleteClient = (element) => {
    let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
    if (localData[clientType]) {
      if (currentlySelectedData?.isCorp) {
        let newDataIndex = localData[clientType].findIndex((el) => el.key === currentlySelectedData?.key);
        if (newDataIndex !== -1) {
          localData[clientType][newDataIndex].signers = localData[clientType][newDataIndex].signers.filter((signer) => signer.key !== element.key);
          setSelectesRows(localData[clientType][newDataIndex].signers);
        }
        // console.log("newDataIndex", newDataIndex);
      } else {
        localData[clientType] = localData[clientType].filter((el) => {
          if (element.key) {
            return el.key !== element.key;
          } else {
            return el.personId !== element.personId;
          }
        });
        setSelectesRows(localData[clientType]?.filter((ele) => !ele.isCorp));
      }
      // console.log(localData[clientType], "testing dd");
      setClientsArray(localData[clientType]);
      localStorage.setItem(currentUrlId, JSON.stringify(localData));
      if (contractAuthKey) {
        dispatch(contractStoreHandle.autoSaveContract({ currentUrlId, contractAuthKey, valueObj: { [clientType]: localData[clientType] } }));
      } else {
        dispatch(documentAction.setpdfImportState({ [clientType]: localData[clientType] }));
      }
    }
    // handleDataChange(data);
  };

  return (
    <div>
      {source !== "CORP" && (
        <div
          
          onClick={() => {
            setAddPerson(!addPersons);
          }}
          style={{ cursor: "pointer" }}
        >
          <text style={{fontSize:"medium"}}>
           Add Person
           </text>
       
        </div>
      )}
      {
        <div style={{ display: "flex", gap: "10px", marginTop: source === "CORP" ? "-5px" : "10px" }}>
          <Input data-no-auto-save data-not-autofill-values allowClear placeholder={source === "CORP" ? "Enter Signer Name" : `Enter ${clientType === "Buyers" ? "Buyer" : "Seller"} Name`} name="no-event" value={clientName} onChange={(e) => setClientName(e.target.value)} onKeyPress={handleKeyPress} />
          <Button type="primary" onClick={handleManualClientAdd} disabled={!clientName?.trim()}>
            Add
          </Button>
        </div>
      }

      {selectedRows?.length > 0 ? (
        <div className={styles.selectedRowsOuterDivParent}>
          <div className={styles.selectedRowsOuterDiv}>
            <List
              dataSource={selectedRows}
              style={{
                marginBottom: "20px",
              }}
              renderItem={(item) => {
                const intials = generateInitialsFromObj(item);
                return (
                  <List.Item key={item._id} style={{ margin: isMobile ? "-5px 5px" : "5px 5px", width: "90%" }}>
                    <List.Item.Meta
                      avatar={
                        intials ? (
                          <Avatar size={45} style={{ background: "#085191" }}>
                            {intials}
                          </Avatar>
                        ) : (
                          <Avatar size={45} icon={<UserOutlined />} style={{ background: "#085191" }} />
                        )
                      }
                      title={<span style={{ fontSize: "15px", display: "inline-block", verticalAlign: "middle", lineHeight: "45px" }}> {item?.fullName || ""}</span>}
                    />
                    <span onClick={() => handleDeleteClient(item)} style={{ cursor: "pointer" }}>
                      <DeleteTwoTone style={{ fontSize: "medium", marginLeft: "8px" }} />
                    </span>
                  </List.Item>
                );
              }}
            />
          </div>
        </div>
      ) : (
       <EmptyData/>
      )}
    </div>
  );
}
